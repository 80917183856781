:root {
    --control-background-color: #f4f9f0;
    --control-border-color: #88c656;
    --border-color-gray: #d5d5d5;
}

html {
    height: 100%;
}

body {
    font-family: sans-serif, "Poppins";
    font-size: 1.2rem !important;
    line-height: 1.42857143;
    color: #333;
    height: 100%;
    overflow: hidden;
}

.body-container {
    height: calc(100% - 60px);
    overflow: auto;
}

.footer-container {
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 9;
}

.footer-panel {
    padding-top: 5px;
}

#root {
    /* overflow: hidden; */
    height: 100%;
}

.invalid-container {
    background-color: #f0f0f0;
    height: 100%;
}

.invalid-header {
    background-color: #0973ba;
    height: 125px;
    padding: 6px;
}

.error-page {
    height: 100%;
}

.error-page-container {
    border-radius: 6px;
    height: 100%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    padding-top: 50%;
}

.error-page-container .message {
    font-size: 3em;
    text-align: center;
}

.signing-page-main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;
    background-color: #e0e0e0;
}

#awesome-pdf-viewer {
    height: 100% !important;
}

.sign-footer-container {
    height: 60px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 9;
    background-color: #ffffff;
}

.shepherd-element[data-shepherd-step-id="btnFinish"]
{
    opacity: 1 !important;
}
.shepherd-element[data-shepherd-step-id="btnSkip"]
{
    opacity: 1 !important;
}

#awesome-pdf-viewer > .main > .right-panel {
    position: relative;
}

#awesome-pdf-viewer > .main {
    height: calc(90vh - 150px);
}

.pdf-preview-container,
#esign-aweosme-pdf-viewer {
    height: 100%;
}

#esign-aweosme-pdf-viewer > .main,
#esign-aweosme-pdf-viewer > .main > .side-panel {
    height: calc(100% - 45px) !important;
}

.view-layout > .main > .side-panel {
    z-index: 9998 !important;
}

.view-layout > .main > .page-viewer {
    z-index: 1;
    border-color: #e0e0e0;
    width: 100%;
}

.read-mode-toolbar {
    width: 355px !important;
}

.steps-wrapper {
    height: calc(100% - 60px);
}

.steps-footer {
    padding-top: 4px;
    position: absolute;
    bottom: 0;
}

.esign-footer-container {
    height: 60px;
}

.esign-footer > .steps-footer {
    height: 60px !important;
}

.steps-body.without-footer {
    height: calc(100% - 55px) !important;
}

.header-container {
    background-color: #0973ba !important;
    /* background-color: var(--headerBgColor); */
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    position: relative;
    z-index: 11;
}

.header-nav {
    margin: auto;
    width: 85%;
    text-align: center;
    color: white;
    /* display: flex; */
    flex-wrap: wrap;
}

.header-nav > .title {
    font-weight: bold;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

.header-nav > .company-logo {
    max-height: 50px;
    margin: auto;
}

.page-container {
    height: calc(100vh - 135px);
    padding: 20px;
}

.page-parts-container {
    border: 2px solid #909090;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    border-width: 3px;
    font-size: 15px;
}

.steps-body {
    border: none !important;
}

.step-layout {
    height: 100%;
}

.step-layout-left-panel-header,
.step-layout-body-header {
    font-size: 20px;
    padding: 0px 0px 5px 0px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.step-layout-body-header > .sub-title {
    font-weight: bold;
    font-size: 14px;
    height: 100px;
    color: #747474;
}

.step-layout-left-panel-body {
    padding: 0px 15px 0px 0px;
}

.step-layout-body {
    padding: 8px 8px 8px 8px !important;
}

.step-layout-left-panel-body,
.step-layout-body {
    height: calc(100%);
    overflow-y: auto;
    overflow-x: hidden;
}

.step-layout-body.without-header {
    height: 100%;
}

.step-layout-body {
    width: 100%;
    border: 2px solid #909090;
    border-radius: 5px;
    margin: auto;
}

.step-layout-content {
    min-height: 400px;
    height: 100%;
    border: none !important;
}

.step-layout-header + .step-layout-content {
    height: calc(100% - 50px);
}

.step-layout-body-container {
    margin: 0px auto 0px auto;
    padding: 8px !important;
    border: none !important;
}

.step-layout-left-panel {
    background-color: white;
}

.step-layout-left-panel,
.step-layout-body-container {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    height: 100%;
}

.step-layout-body.white {
    background-color: white;
}

.step-layout-body.transparent {
    background-color: transparent;
}

#awesome-multi-steps-btn-previous {
    background-color: white !important;
    border-color: #0973ba !important;
    color: #0973ba !important;
}

.dropdown-toggle.btn.btn-success {
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-color: var(--control-border-color);
    border-radius: 4px !important;
    width: 100px;
    text-align: left;
}

.dropdown-toggle.btn.btn-success::after {
    position: absolute;
    right: 15px;
    top: 18px;
}

.YearMonthDay.col {
    display: inline-flex;
}

.select-control {
    margin: auto;
}

.select-control > .dropdown {
    width: max-content;
}

.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    display: inline !important;
    font-size: 25px !important;
}

/*Custom Checkbox START*/
/* Customize the label (the container) */
.consent-checkbox {
    display: block;
    position: relative;
    padding: 10px 0px 0px 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.consent-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    top: 20px;
    left: 20px;
    z-index: 99;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid var(--control-border-color);
    border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.consent-checkbox input:hover ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.consent-checkbox input:checked ~ .checkmark {
    background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.consent-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.consent-checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 7px;
    height: 11px;
    border: solid #9fe350;
    border: solid var(--control-border-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*Custom Checkbox END*/

.custom-input {
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-color: var(--control-border-color);
    border-radius: 5px !important;
    width: 100%;
    width: 100%;
    border-width: 2px;
    height: 40px;
    font-size: 20px;
}

#controlled-tab-example-tabpane-home {
    height: 100%;
    background-color: transparent !important;
}

#controlled-tab-example-tabpane-profile {
    height: 100%;
    background-color: white !important;
}

.payment-refund-container {
    height: 100%;
    margin-right: 0px !important;
    padding-right: 0px !important;
    background-color: white;
}

.payment-refund-container .title,
.estimated-voucher-container .title,
.tax-payment-container .title,
.payment-instruction-container .title {
    margin-bottom: 5px;
    margin-left: 0px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
    color: #000000;
}

.payment-refund-container .body,
.estimated-voucher-container .body {
    padding: 0px 10px;
    overflow: auto;
    height: 100%;
}

.section-title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #0973ba;
    clear: both;
}

.preSelect {
    clear: both;
    margin: 20px 1px;
}

.sub-section {
    clear: both;
}

.sub-section div {
    width: 50%;
    position: relative;
}

.sub-section .authority {
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
}

.sub-section .amount {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
}

.sub-section .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: inherit;
    font-size: 15px;
}

.total-amount-green {
    border-top: 1px solid #000;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    width: 40% !important;
}

.currencytext {
    text-align: right;
    padding-right: 0px;
    /*font-family: monospace;*/
    font-family: sans-serif, "Roboto", "Poppins";
}

.flrt {
    float: right;
}

.orange {
    color: darkorange;
}

.green {
    color: #86bf51;
}

.red {
    color: #e00d0d;
}

.bold {
    color: #000;
}

.fllft {
    float: left;
}

.total-amount-red {
    border-top: 1px solid #000;
    font-size: 20px;
    font-weight: 700;
    width: 40% !important;
}

.nav-tabs {
    border-radius: 5px !important;
}

.nav-tabs .nav-link {
    border: 2px solid #8bc656 !important;
    font-size: 18px !important;
    flex: 1 1 auto;
    text-align: center;
    padding: 10px 5px 10px 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #0973ba !important;
    background-color: #86bf516e !important;
    font-size: 18px;
    border: 2px solid #86bf51 !important;
}

.kba-questions {
    font-weight: bold;
}

.form-check-input {
    width: 20px;
    height: 18px;
}

.form-check-label {
    margin-bottom: 10px !important;
    margin-left: 5px !important;
}

.sign-completed-body {
    text-align: center;
}

.sign-completed-icon {
    height: 85px;
}

.custom-button {
    background-color: #5ffc5f1c;
    border: 2px solid #83c786;
    height: 50px;
    border-radius: 5px;
    display: flex;
    padding: 8px;
    cursor: pointer;
}

.custom-button.left {
    float: left;
}

.custom-button.center {
    margin: auto;
}

.custom-button.xl {
    width: 285px;
}

.custom-button.lg {
    width: 225px;
}

.custom-button.md {
    width: 175px;
}

.custom-button.sm {
    width: 125px;
}

.custom-button.right {
    float: right;
}

.custom-button.right {
    float: right;
}

.custom-button:hover {
    background-color: #9fff9f6b;
}

.custom-button-icon-container {
    width: 25px;
}

.custom-button-text-container {
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bold;
    font-size: 14px !important;
    margin: auto auto auto 10px;
    font-family: sans-serif, "Roboto", "Poppins";
}

#overlay-loader[data-show="true"] {
    display: block !important;
}

#overlay-loader {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: 0.8;
}

#overlay-loader > .spinner {
    margin: 0 auto;
    height: 40px;
    width: 40px;
    animation: rotate 0.8s infinite linear;
    border: 3px solid #0973ba;
    /*border: 3px solid var(--headerBgColor);*/
    border-right-color: transparent;
    border-radius: 50%;
    top: 50%;
    left: 43%;
    position: fixed;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Bootstrap Modal Customization */

.modal-dialog {
    max-width: 900px !important;
    border-radius: 5px !important;
}

.modal-content {
    border: 3px solid #89c05a !important;
    border-radius: 5px !important;
}

.modal-body{
    overflow-y: auto;
}

.bootbox-accept {
    border-radius: 25px !important;
    color: white !important;
    border-color: #9fe350 !important;
    background-color: #89c05a !important;
    width: 115px !important;
}

.bootbox-cancel {
    background-color: white !important;
    border-color: #0973ba !important;
    color: #0973ba !important;
    border-radius: 25px !important;
    width: 115px !important;
}

.bootbox-title-icon {
    margin: 0px 10px 0px 0px;
}

.modal-footer {
    display: block !important;
    text-align: right !important;
}

/* End Of Bootstrap Modal Customization */

.button-bg {
    padding: 0px 20px 0px 20px;
    font-size: 20px !important;
    height: 50px;
    border-radius: 5rem !important;
    color: white !important;
    border-color: #9fe350 !important;
    background-color: #89c05a !important;
    font-weight: 600;
}

.button-bg[disabled] {
    opacity: 0.5;
}

.summary-begin-button {
    margin-top: 15px;
}

.summary-begin-button-container {
    text-align: center;
}

.step-layout-body > .tab-content {
    height: 78%;
}

.signature-control {
    font-size: 9px !important;
}

.kba-questions-list {
    height: calc(100% - 175px);
    overflow-y: auto;
    overflow-x: hidden;
}

.btn-primary {
    background-color: #88c656 !important;
    border: 1px solid #88c656;
    border-color: #88c656 !important;
}

.btn-primary,
.btn-light,
.btn-info {
    border-radius: 50px !important;
    min-width: 100px;
    font-weight: 500;
    font-size: 14px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #88c656 !important;
    border-color: #88c656 !important;
}

.btn-secondary {
    min-width: 0 !important;
    border: 2px solid #8bc656 !important;
    background-color: #f3f9ee !important;
    color: black !important;
    min-width: 100px !important;
    border-radius: 5rem !important;
}

.btn-secondary:hover {
    background-color: #e8f4dd;
    border-color: #9fe350;
}

.btn-secondary:active {
    background-color: #e8f4dd !important;
    border-color: #9fe350 !important;
}

div#ddlCountryCode {
    width: 150px;
}

.phoneNumeberTextBox {
    height: 41px !important;
    border-radius: 5px !important;
}

.spouse-details-phone-number-container {
    display: inline-flex;
}

.bootbox > .modal-dialog {
    margin: 0.5rem !important;
}

/*.modal-dialog {
    margin: -50px auto 0px auto
}*/

.modal-title {
    width: 100%;
    float: right;
    margin-right: 10px;
}

.popover {
    border: 1px solid #0973ba;
    z-index: 9999;
    /*border: 1px solid var(--headerBgColor);*/
}

.popover-header {
    background-color: #0973ba;
    /*background-color: var(--headerBgColor) !important;*/
    color: white;
    /*color: var(--headerForeColor);*/
    font-size: 12px;
    font-weight: bold;
    margin: 1px;
}

.rct-node-icon {
    color: #717171;
}

li span.rct-text span.rct-node-clickable {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 200px !important;
}

.rct-node-clickable:focus {
    outline: 0;
    background: transparent;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
    display: none;
}

.react-checkbox-tree {
    display: block;
}

.header-left-container {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: auto;
}

.header-right-container {
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 6%;
    min-width: 175px;
}

.header-account {
    margin-right: 15px;
}

.header-mydownload,
.header-contact-info,
.header-logout,
.header-account,
.header-taxYear,
.header-taxReturn {
    float: right;
    color: white;
    color: var(--headerForeColor);
    cursor: pointer;
    margin-left: 15px;
    padding: 12px 0px 0px 5px;
}

.header-contact-info a {
    color: white;
    color: var(--headerForeColor);
}

.header-contact-info a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-contact-info:hover,
.header-logout:hover {
    text-decoration: underline;
}

.header-taxYear a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-account a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-taxYear:hover {
    cursor: text;
}

.mail {
    font-size: 14px;
    color: #88c656;
    font-weight: bold;
}

.mail a:hover {
    cursor: pointer;
}

.account-menu,
.taxYear-menu,
.taxReturn-menu {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

.header-account a::after
/*, .header-taxYear a::after, .header-taxReturn a::after*/ {
    content: "";
    width: 0px;
    height: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #ffffff;
    position: absolute;
    margin-left: 5px;
    margin-top: 8px;
    z-index: 100;
}

#sidebarMenu {
    height: calc(100% - 60px);
    position: fixed;
    left: 0;
    width: 225px;
    margin-top: 60px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    /*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
    background-color: #0973ba;
    /*background-color: var(--headerBgColor);*/
    overflow: scroll;
    z-index: 9999 !important;
}

.sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

.sidebarMenuInner li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

#openSidebarMenu {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    margin: 20px 0px 0px 0px;
    height: 22px;
    width: 22px;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}

.show {
    display: block !important;
}

.sidebar {
    width: 225px !important;
    min-height: calc(100% - 42px);
    user-select: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1.5rem !important;
}

.navbar-nav {
    float: left;
    margin: 0;
}

.navbar-nav > li {
    text-align: left;
    border-top: 1px solid #8e8e8e99;
    font-size: 15px;
    padding: 10px;
}

.navbar-nav > li:first-child {
    border-top: none;
}

.navbar-nav > li:first-child > h4 {
    border-top: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navbar-nav > li:last-child {
    border-bottom: 1px solid #8e8e8e99;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
    position: relative;
}

.sidebar .nav-item .nav-link {
    display: block;
    text-align: left;
    padding: 1rem;
    width: 225px;
}

.sidebar .nav-item .nav-link {
    color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
    display: block;
}

navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
    margin: 10px;
}

.sidebar .nav-item .nav-link span {
    font-size: 1.5rem;
    display: inline;
}

.nav-link span {
    color: #fff;
    text-shadow: none;
}

.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu {
    box-shadow: none;
    position: static !important;
    margin-left: 30px;
    top: 0;
    background-color: transparent;
    border: none;
}

.navbar-nav .layout-dropdown-menu {
    position: static;
    float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(50px + 0.5rem) !important;
    margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

.layout-dropdown-menu.show {
    padding-top: 0px;
}

a.active.layout-dropdown-item {
    text-decoration: underline;
}

.myaccount-text.phonenumber {
    display: inline-flex;
}

.myaccount .modal-title {
    color: #0973ba;
    /*color: var(--headerBgColor);*/
    font-size: 14px;
    font-weight: bold;
}

.myaccount-text {
    padding: 10px;
    font-size: 12px;
}

.my-account-phonenumber {
    font-size: 15px;
    height: 38px !important;
}

.myaccount-text {
    padding: 10px;
    font-size: 12px;
}

.myaccount-changenumber {
    padding-right: 20px;
    padding-left: 4px;
}

.myaccount-countrycode {
    padding-right: 0px;
    padding-left: 20px;
}

.shepherd-footer .shepherd-button {
    font-size: 12px;
}

.modal {
    z-index: 9999;
}

.steps-wrapper > .steps-body > .step {
    animation-fill-mode: none !important;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

footer {
    padding: 10px 5px 5px 5px;
}

.document-control {
    min-height: 15px !important;
    min-width: 50px !important;
}

.otp-page {
    padding-top: 15px !important;
    overflow: auto;
    height: calc(100% - 60px);
}

.otp-page-container {
    border-radius: 6px;
    /*padding: 12% 10%;*/
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    border: 2px solid #909090;
    height: 50%;
    overflow: auto;
}

.otp-page-container input {
    border: 2px solid #8bc656;
    border-radius: 5px;
    background-color: #f3f9ee;
    width: 160px;
    height: 40px;
    color: #000;
    padding: 5px;
    font-weight: bold;
    font-size: 16px;
}

.otp-page-container input::-webkit-input-placeholder {
    font-weight: normal;
}

.otp-page-container input::-moz-placeholder {
    font-weight: normal;
}

.otp-page-container input:-ms-input-placeholder {
    font-weight: normal;
}

.otp-page-container input:-o-input-placeholder {
    font-weight: normal;
}

.otp-page-container input[type="radio"] {
    width: 70px;
    height: 20px;
}

.float-right {
    float: right;
}

.btn-primary-accesscode {
    border-radius: 50px;
    min-width: 150px;
    font-weight: 500;
    font-size: 16px;
    border: 2px solid #5a56c6;
    width: 225px;
    height: 40px;
    color: #5a56c6;
}

.access-code-expiry-info {
    font-style: italic;
}

.margin-top-30 {
    margin-top: 30px;
}

/* CoverPage and Header Styling */

.company-info-container {
    height: 45%;
    padding: 0px 10px 10px 10px;
}

.welcome-page {
    padding: 3%;
    height: calc(100% - 60px);
    overflow: auto;
}

.welcome-page-container {
    background-image: url(../images/coverpage-backgroung.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 6%;
    min-height: 100vw;
}

.company-info-container {
    height: 25%;
    width: 75%;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 1px 3px #000 inset;
    -moz-box-shadow: 0 0 1px 3px #000 inset;
    box-shadow: 0 0 1px 3px #cbcbcb6b inset;
    box-shadow: inset 0 -9px 8px -12px #000000;
    box-shadow: inset 1 -9px 8px -12px #000000;
    box-shadow: inset 2px 3px 5px 2px #b7b7b796;
    margin: 12%;
    position: absolute;
    border: 1px solid #b7b7b796;
    padding: 10px;
    text-align: center;
    margin: 16% 7% 0% 7%;
}

.prepared-by-img {
    max-width: 100%;
    max-height: 90%;
    margin-top: 12px;
}

.prepared-by-company-name {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.tax-client-info-container {
    height: 25%;
    width: 75%;
    border-radius: 5px;
    background-color: #8bc656;
    position: absolute;
    display: flex;
    user-select: none;
    margin: 7% 7% 0% 7%;
}

.tax-client-info-label {
    margin: auto auto auto 10px;
    color: white;
    font-weight: 600;
    font-size: initial;
}

.tax-client-info-text {
    height: 85%;
    width: 86%;
    background-color: white;
    margin: 10px 10px 10px 10px;
    -webkit-box-shadow: 0 0 1px 3px #000 inset;
    -moz-box-shadow: 0 0 1px 3px #000 inset;
    box-shadow: 0 0 1px 3px #cbcbcb6b inset;
    box-shadow: inset 0 -9px 8px -12px #000000;
    box-shadow: inset 1 -9px 8px -12px #000000;
    box-shadow: inset 2px 3px 5px 2px #b7b7b796;
    border-radius: 10px;
    /* margin: 10px 0px 0px 15px; */
    padding: 13px;
    text-align: center;
    border: 1px solid #b7b7b7eb;
    font-size: initial;
}

.tax-year-header {
    /*font-weight: 600;*/
}

.tax-client-name {
    margin-top: 5px;
    font-weight: bold;
}

.btn-organizer-continue {
    background-color: yellow;
    width: 180px;
    font-weight: 600;
    border-radius: 25px !important;
}

.ErrorContainer {
    background-color: #f0f0f0;
    height: 100vh;
}

.ErrorPageContent {
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Errorheader {
    background-color: #0973ba;
    height: 60px;
}

.MessageContainer {
    background: white;
    height: 60vh;
    width: 70vw;
    border-radius: 5px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ErrorMsgPara {
    font-size: 18px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.header-title {
    color: white;
    text-align: center;
    padding-top: 15px;
}

.align-img-center {
    display: flex;
    align-items: center;
    justify-items: center;
}

/* END of CoverPage and Header Styling */

/* Upload Documents Page Styling */

.flex {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    height: calc(100% - 70px);
}

.upload-documents {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
}

.outline-container {
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem;
    border: 1px solid #6b7280;
    border-radius: 0.2rem;
    font-size: 16px;
}

.upload-documents-container {
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem;
    border: 1px solid #6b7280;
    border-radius: 0.2rem;
    font-size: 16px;
}

.upload-documents-table-header {
    display: flex;
    justify-content: space-between;
}

.upload-documents-table-header > a {
    pointer-events: none;
    align-self: center;
}

.upload-documents-table-content {
    height: calc(100% - 20px);
    overflow: hidden;
    overflow-y: scroll;
}
.upload-documents-table-warning-msg{
    padding-top: 5px;
    font-size: 14px;
}

.upload-documents-table-content > table {
    width: 100%;
    font-size: 14px;
    margin-top: 1rem;
}

.upload-documents-message-content {
    margin: 0.2rem;
}

.upload-documents-form {
    float: left;
}

.upload-documents-form > label > input {
    display: none;
}

.camera-icon {
    width: 1.6rem;
    margin-top: 0.2rem;
}

.upload-documents-form > label > .camera-icon {
    width: 1.6rem;
    margin-top: -0.2rem;
    margin-left: .2rem;
    margin-right: .5rem;
}

.document-container {
    margin-top: 0.5rem;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-documents-table-col {
    vertical-align: top;
}

.left-column {
    width: 65%;
}

.right-column {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left {
    width: 1.6rem;
    margin-right: 1rem;
}

.right {
    width: calc(100% - 1.6rem);
}

.container-one {
    height: 70px;
}

.upload-documents-message {
    height: 70px;
}

.container-two {
    flex: 1;
    height: calc(100% - 80px);
}

.documents-footer-container {
    height: 70px;
}

.upload-documents-table {
    flex: 1;
    height: calc(100% - 130px);
}

.upload-documents-footer {
    height: 60px;
}

@media only screen and (max-width: 345px) {
    .upload-documents-message-content {
        font-size: 0.9rem;
    }

    .upload-documents-table-header > a {
        font-size: 0.9rem;
    }

    .right {
        font-size: 0.8rem;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* End of UploadDocuments Page */

.btn-size {
    width: 100%;
    height: auto;
    font-size: 10px;
}

.btn-notify-tax-professional {
    height: auto;
}
.welcome-popup-message .modal-footer {
    text-align: center !important;
    border-top: "none" !important;
}

/* .welcome-popup-message .modal-dialog {
    margin-top: 25%;
} */
.welcome-popup-message {
    text-align: center;
}

.welcome-client-page-container {
    border: 3px solid lightgrey;
    border-radius: 5px;
    min-height: 85%;
    margin-left: 0px;
    margin-right: 0px;
}

.engagementLetterButton {
    border: 3px solid lightgrey;
    margin: 0.7rem;
    border-radius: 5px;
}

.btn-welcome-start {
    margin: 0px;
    color: white !important;
    background-color: #88c656;
    width: 220px;
    font-weight: 600;
    border-radius: 25px !important;
}

.margin-5 {
    margin: 5px;
}

.welcome-client-page {
    height: 100%;
    min-height: 500px;
    padding-top: 5%;
}

.welcome-navigate-button {
    height: 15%;
}

.welcome-navigate-button-container {
    align-self: center;
    text-align: center;
}

.welcomepage-clientDetails.col-12 {
    padding-right: 0px;
    padding-left: 0px;
}

.welcome-action-text {
    color: #0973ba;
    font-weight: 600;
}

.engagementLetterButton .col-2 {
    padding-right: 0px;
    padding-left: 0px;
}

.engagementLetterButton .col-10 {
    padding-right: 0px;
    padding-left: 0px;
}

.popover-contact {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(121px, 91px, 0px);
}

.arrow {
    margin: 0px;
    position: absolute;
    top: 0px;
    transform: translate3d(0px, 38px, 0px);
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.logout-no {
    background-color: white !important;
    border-color: #0973ba !important;
    color: #0973ba !important;
    border-radius: 25px !important;
    width: 115px !important;
}

.contact-info-button-overlay {
    background-color: #0973ba;
    color: inherit;
    border: none;
    padding: 0px;
}

/* { End }*/

/* View Documents Page Styling */

.view-documents {
    display: flex;
    flex-direction: column;
    height: calc(100% - 68px);
    border: 1px solid #6b7280;
    margin: 8px 8px 0 8px;
}

.view-documents > p {
    margin: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1.1rem;
}

.view-documents-content {
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.view-documents-content-row {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.view-documents-content-col1 {
    margin: 0 1rem;
    height: 65px;
    width: 65px;
}

.view-documents-content-col2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
}

.view-documents-content-col2 > p {
    font-size: 0.9rem;
    width: 55vw;
    overflow-wrap: break-word;
    hyphens: initial;
}

.view-documents-content-col2 > div {
    display: flex;
    align-items: center;
}

.view-documents .preview-icon {
    height: 25px;
    margin-right: 3rem;
}

.view-documents .trash-icon {
    height: 20px;
}

.preview-icon {
    margin-right: 3rem;
}

.download-icon {
    margin-left: 3rem;
    height: 1.5rem;
    margin-top: 0px;
}

.column-1 {
    height: 80px;
}

@media only screen and (max-width: 350px) {
    .view-documents-content-col1 {
        width: 70vw;
        height: 70px;
    }
}

@media only screen and (max-width: 330px) {
    .view-documents-content-col1 {
        width: 60vw;
        height: 60px;
    }
}

/* View Documents Page End */

/* Viewer Modal */

.modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
    overflow-y: scroll;
}

.viewer-content-image {
    width: 100%;
}

.viewer-content-docs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download-btn {
    margin-bottom: 5px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.5rem;
    flex-wrap: wrap;
}

.section-header > span {
    max-width: 90%;
    word-break: break-all;
}

.section-header > div {
    width: 10%;
    align-self: flex-start;
}

.section-view {
    height: 100%;
    overflow: scroll;
    max-height: calc(100vh - 15rem);
}

.viewer-filename {
    height: fit-content;
    word-break: break-word;
}

.disabledEngagementButton {
    pointer-events: none;
    opacity: 0.3;
}

.preparer-message-page-container {
    border: 3px solid lightgrey;
    border-radius: 5px;
    height: 85%;
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
}

.preparer-message-content {
    height: 85%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 20px;
}

.prepare-message-header {
    padding-top: 5px;
    font-weight: bold;
    padding-left: 20px;
}

.btn-complete {
    color: #fff !important;
    border: 1px solid #017cff !important;
    background-color: #017cff !important;
    min-width: 100px !important;
    border-radius: 5rem !important;
}

.font-size-14p {
    font-size: 14px;
}

.font-size-16p {
    font-size: 16px;
}

.font-weight-bold {
    font-weight: bold;
}

.margin-right-10p {
    margin-right: 10px;
}

.awesome-pdf-viewer-radio-button:checked + .awesome-pdf-viewer-radio-button-label:before {
    font-family: 'Font Awesome 5 Free' !important;
    top: -3px !important;
    font-weight: 900 !important;
}
.canvasWrapper
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.document-control
{
    z-index: 10;   
}

#awesome-pdf-viewer .header {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
}

div.shepherd-element {
    z-index: 9 !important;
}

/* Toolbar CSS */
#awesome-pdf-viewer .left-section,
#awesome-pdf-viewer .right-section {
    position: absolute;
    right: 0;
}

.col-md-1{
    max-width: 18%;
}

#awesome-pdf-viewer .middle-section {
    display: flex;
    justify-content: center;
}

@media (max-width: 481px) {
    div.shepherd-content {
        width: 40px !important;
    }
}

.area-disabled {
    cursor: not-allowed;
    opacity: .3;
    pointer-events: none;
}

.btn-decline {
    background: #CC4A43 !important;
    margin-right: 6px;
    border-color: #CC4A43 !important;
    border-radius: 50px !important;
    min-width: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    box-shadow: 0 0 0 0 !important;
}

.decline-modal {
    text-align: start;
}
