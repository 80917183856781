body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.loader__container {
    position: absolute;
    background-color: #f3f4f6;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 999;
}

.loader {
    position: absolute;
    top: 40%;
    left: 47%;
}

@media only screen and (max-width: 500px) {
    .loader {
        left: 37%;
    }
}
